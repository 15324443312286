import React from 'react';

import BlogView from '../views/blogView/index';

const BlogPage = ({ data }) => {
  return <BlogView data={data} label={'Get Started'} />;
};

export default BlogPage;

export const pageQuery = graphql`
  {
    allContentfulBlog(filter: { category: { in: "get_started" } }) {
      edges {
        node {
          postTitle
          category
          postThumbnail {
            file {
              url
              fileName
            }
          }
          slug
        }
      }
    }
  }
`;
